import { usePositionContext } from '@search/Facets/FacetComponents/Position';
import RenderPositions from './RenderPositions';

function PositionContainer() {
  const { asset, grid } = usePositionContext();
  const containerSize = {
    width: `${grid.x}px`,
    height: `${grid.y}px`,
  };

  if (!asset) {
    return null;
  }

  return (
    <div className="flex min-h-full min-w-full items-center justify-center">
      <div
        className="relative bg-white"
        style={{
          width: containerSize.width,
          height: containerSize.height,
          minWidth: containerSize.width,
          minHeight: containerSize.height,
        }}
      >
        <img className="size-full" alt={asset?.title} src={asset?.image} />
        <RenderPositions />
      </div>
    </div>
  );
}

export default PositionContainer;

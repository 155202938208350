import { searchStore } from '@context/searchStore';
import Icon from '@elements/Icon';
import { Menu, Transition } from '@headlessui/react';
import { createSearchQuery } from '@helpers/searchQuery';
import { useRouter } from 'next/router';
import { Fragment } from 'react';
import MenuItem from './MenuItem';

const sortOptions = [
  { label: 'Relevantie', value: 'Relevantie' },
  { label: 'Titel A-Z', value: 'Title asc' },
  { label: 'Titel Z-A', value: 'Title desc' },
  { label: 'Datum oplopend', value: 'Date asc' },
  { label: 'Datum aflopend', value: 'Date desc' },
];

export default function SortDropdown() {
  // Gets the current search store
  const searchState = searchStore((state) => state.searchState);
  const selectedSortLabel = sortOptions.filter((x) => x.value == searchState.sort)[0];
  const router = useRouter();

  function setSelectedItem(value) {
    const query = createSearchQuery('sort', value, router);
    router.push(query.query, query.visibleQuery, query.scroll);
  }

  return (
    <Menu as="div" className="relative h-11 w-48 bg-accent-3">
      <Menu.Button className="flex w-full items-center justify-between gap-3 px-4 py-2 font-secondary text-primary-dark">
        {selectedSortLabel?.label}
        <Icon iconName="chevron-down" color="text-accent" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-20 w-full origin-top-left overflow-auto bg-white shadow-lg">
          {sortOptions.map((option) => (
            <MenuItem
              key={option}
              label={option.label}
              value={option.value}
              searchStateValue={searchState.sort}
              setSelectedItem={() => setSelectedItem(option.value)}
            />
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

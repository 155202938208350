import Modal from '@elements/Modal';
import PositionSelector from '@elements/PositionSelector';
import Button from '@elements/buttons/Button';
import { useRouter } from 'next/router';
import { createContext, useContext, useEffect, useState } from 'react';
import { visualFacetConfig } from '../VisualFacetConfig';

const grid = {
  x: 400,
  y: 400,
};

export const PositionContext = createContext();
export function usePositionContext() {
  return useContext(PositionContext);
}

export function PositionFacet({ facetDataValues }) {
  const config = visualFacetConfig;
  const router = useRouter();

  const positionsFacet = Array.isArray(facetDataValues)
    ? facetDataValues.map((facet) => {
        const id = parseInt(facet.label.split(' ')[0], 10);
        return {
          ...facet,
          id,
        };
      })
    : [];

  const [showModal, setShowModal] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedAsset, setSelectedAsset] = useState(0);
  const [asset, setAsset] = useState(config[0].assets[0]);

  useEffect(() => {
    if (router.query.ReferenceDetailPosition) {
      const positionValue = router.query.ReferenceDetailPosition;
      setSelectedPosition(positionValue || null);
    }
  }, [router.query.ReferenceDetailPosition]);

  const onClick = () => {
    setShowModal(!showModal);
  };

  function resetFilter() {
    setSelectedPosition(null);
    // eslint-disable-next-line no-unused-vars
    const { ReferenceDetailPosition, ...restQuery } = router.query;
    delete restQuery.ReferenceDetailPosition;

    router.replace(
      {
        pathname: router.pathname,
        query: restQuery,
      },
      undefined,
      { shallow: false },
    );
  }

  const findImageForPosition = (positionId) => {
    for (const facet of visualFacetConfig) {
      for (const asset of facet.assets) {
        if (asset.positions.some((pos) => pos.id + ' ' + pos.title === positionId)) {
          return asset.image;
        }
      }
    }
    return '/visual_facets/base.svg';
  };

  const selectedPositionImage = selectedPosition
    ? findImageForPosition(selectedPosition)
    : '/visual_facets/base.svg';

  return (
    <>
      {router.query.ReferenceDetailPosition && (
        <button
          className="pb-2 pr-2 font-secondary font-light text-accent underline"
          onClick={resetFilter}
        >
          herstel filter
        </button>
      )}
      <div className="flex flex-col items-center gap-6">
        <div className="size-60 rounded-md bg-accent-4">
          <img
            className="object-cover text-xs"
            src={selectedPositionImage}
            alt="Position"
            onError={(e) => (e.target.src = '/visual_facets/base.svg')}
          />
        </div>
        <Button buttonType="primary" label="Zoek op positie" onClick={onClick} />
      </div>
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        modalWidth={'xl'}
        modalTitle="Zoek op positie"
        showCloseButton={true}
        modalContent={
          <PositionContext.Provider
            value={{
              config,
              grid,
              asset,
              setSelectedPosition,
              selectedPosition,
              setShowModal,
              setSelectedTab,
              selectedTab,
              setAsset,
              setSelectedAsset,
              selectedAsset,
              positionsFacet,
            }}
          >
            <PositionSelector modalWidth="xl" />
          </PositionContext.Provider>
        }
      />
    </>
  );
}

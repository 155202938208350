import { Menu } from '@headlessui/react';

export default function MenuItem(props) {
  const { label, value, setSelectedItem } = props;
  return (
    <Menu.Item>
      {({ active }) => (
        <button
          className={`
          ${
            active ? 'rounded bg-accent text-white/90' : 'text-primary-dark'
          } flex w-full items-center p-3 font-secondary`}
          onClick={() => setSelectedItem(value)}
        >
          {label}
        </button>
      )}
    </Menu.Item>
  );
}

export const visualFacetConfig = [
  {
    id: 1,
    title: 'Woning',
    assets: [
      {
        id: 1,
        title: 'Fundering',
        image: '/visual_facets/w_fundering.svg',
        positions: [
          {
            id: 101,
            title: 'fundering - langsgevel',
            type: 'wbouw',
            coordinates: { x: 144, y: 280 },
            selectable: false,
          },
          {
            id: 102,
            title: 'fundering - langsgevel ter plaatse van deur',
            type: 'wbouw',
            coordinates: { x: 170, y: 296 },
            selectable: false,
          },
          {
            id: 103,
            title: 'fundering - kopgevel',
            type: 'wbouw',
            coordinates: { x: 272, y: 261 },
            selectable: false,
          },
          {
            id: 104,
            title: 'fundering - bouwmuur',
            type: 'wbouw',
            coordinates: { x: 211, y: 220 },
            selectable: false,
          },
          {
            id: 105,
            title: 'fundering - berging',
            type: 'wbouw',
            coordinates: { x: 330, y: 207 },
            selectable: false,
          },
          {
            id: 106,
            title: 'fundering - langsgevel aanbouw',
            type: 'wbouw',
            coordinates: { x: 21, y: 248 },
            selectable: false,
          },
          {
            id: 107,
            title: 'fundering - kopgevel aanbouw',
            type: 'wbouw',
            coordinates: { x: 71, y: 259 },
            selectable: false,
          },
          {
            id: 108,
            title: 'fundering - aansluiting vloer aanbouw op bestaande vloer',
            type: 'wbouw',
            coordinates: { x: 67, y: 238 },
            selectable: false,
          },
          {
            id: 110,
            title: 'fundering - kopgevel ter plaatse van deur',
            type: 'wbouw',
            coordinates: { x: 230, y: 289 },
            selectable: false,
          },
          // {
          //   id: 111,
          //   title: 'fundering - bouwmuur ter plaatse van onverwarmde zone',
          //   type: 'wbouw',
          //   coordinates: { x: 10, y: 20 },
          //   selectable: false,
          // },
          // {
          //   id: 112,
          //   title: 'fundering ter plaatse van loggia evenwijdig aan gevel, onderkant kozijn',
          //   type: 'wbouw',
          //   coordinates: { x: 10, y: 20 },
          //   selectable: false,
          // },
          // {
          //   id: 113,
          //   title: 'fundering ter plaatse van loggia haaks op de gevel, onderkant kozijn',
          //   type: 'wbouw',
          //   coordinates: { x: 10, y: 20 },
          //   selectable: false,
          // },
          {
            id: 114,
            title: 'fundering - langsgevel aanbouw ter plaatse van deur',
            type: 'wbouw',
            coordinates: { x: 34, y: 257 },
            selectable: false,
          },
        ],
      },
      {
        id: 2,
        title: 'Vloerongebonden en uitbouw',
        image: '/visual_facets/w_vloerongebonden_en_uitbouw.svg',
        positions: [
          {
            id: 201,
            title: 'langsgevel - onderkant kozijn',
            type: 'wbouw',
            coordinates: { x: 232, y: 212 },
            selectable: false,
          },
          {
            id: 202,
            title: 'langsgevel - zijkant kozijn',
            type: 'wbouw',
            coordinates: { x: 252, y: 200 },
            selectable: false,
          },
          {
            id: 203,
            title: 'langsgevel - bovenkant kozijn',
            type: 'wbouw',
            coordinates: { x: 232, y: 180 },
            selectable: false,
          },
          {
            id: 204,
            title: 'langsgevel - bouwmuur',
            type: 'wbouw',
            coordinates: { x: 181, y: 246 },
            selectable: false,
          },
          {
            id: 205,
            title: 'langsgevel - kopgevel',
            type: 'wbouw',
            coordinates: { x: 271, y: 202 },
            selectable: false,
          },
          {
            id: 206,
            title: 'langsgevel - langsgevel (binnenhoek)',
            type: 'wbouw',
            coordinates: { x: 141, y: 236 },
            selectable: false,
          },
          {
            id: 207,
            title: 'langsgevel - bouwmuur ter plaatse van kozijn',
            type: 'wbouw',
            coordinates: { x: 182, y: 180 },
            selectable: false,
          },
          {
            id: 208,
            title: 'langsgevel - aanbouw ter plaatse van kozijn',
            type: 'wbouw',
            coordinates: { x: 238, y: 294 },
            selectable: false,
          },
          {
            id: 209,
            title: 'langsgevel - overgang gevelbekleding',
            type: 'wbouw',
            coordinates: { x: 137, y: 167 },
            selectable: false,
          },
          {
            id: 210,
            title: 'kopgevel uitbouw - kopgevel bestaand',
            type: 'wbouw',
            coordinates: { x: 268, y: 271 },
            selectable: false,
          },
          {
            id: 211,
            title: 'kopgevel uitbouw - bouwmuur bestaand',
            type: 'wbouw',
            coordinates: { x: 92, y: 232 },
            selectable: false,
          },
          {
            id: 212,
            title: 'langsgevel - binnenwand',
            type: 'wbouw',
            coordinates: { x: 208, y: 189 },
            selectable: false,
          },
          {
            id: 213,
            title: 'langsgevel - gekoppeld kozijn',
            type: 'wbouw',
            coordinates: { x: 211, y: 289 },
            selectable: false,
          },
          {
            id: 214,
            title: 'langsgevel - gestapeld kozijn',
            type: 'wbouw',
            coordinates: { x: 219, y: 267 },
            selectable: false,
          },
          {
            id: 216,
            title: 'kopgevel uitbouw - bouwmuur bestaand',
            type: 'wbouw',
            coordinates: { x: 92, y: 201 },
            selectable: false,
          },
          {
            id: 217,
            title: 'kopgevel - onderkant kozijn',
            type: 'wbouw',
            coordinates: { x: 303, y: 269 },
            selectable: false,
          },
          {
            id: 218,
            title: 'kopgevel - zijkant kozijn',
            type: 'wbouw',
            coordinates: { x: 315, y: 252 },
            selectable: false,
          },
          {
            id: 219,
            title: 'kopgevel - bovenkant kozijn',
            type: 'wbouw',
            coordinates: { x: 301, y: 242 },
            selectable: false,
          },
          {
            id: 220,
            title: 'langsgevel - hoek zijkant kozijn met bovenkant kozijn 3D',
            type: 'wbouw',
            coordinates: { x: 252, y: 183 },
            selectable: false,
          },
          {
            id: 221,
            title: 'langsgevel - hoek zijkant kozijn met onderkant kozijn 3D',
            type: 'wbouw',
            coordinates: { x: 252, y: 217 },
            selectable: false,
          },
        ],
      },
      {
        id: 3,
        title: 'Vloer',
        image: '/visual_facets/w_vloer.svg',
        positions: [
          {
            id: 301,
            title: 'verdiepingsvloer - langsgevel',
            type: 'wbouw',
            coordinates: { x: 117, y: 207 },
            selectable: false,
          },
          {
            id: 302,
            title: 'verdiepingsvloer - langsgevel met bovenkant kozijn',
            type: 'wbouw',
            coordinates: { x: 138, y: 217 },
            selectable: false,
          },
          {
            id: 303,
            title: 'verdiepingsvloer - kopgevel',
            type: 'wbouw',
            coordinates: { x: 320, y: 246 },
            selectable: false,
          },
          {
            id: 304,
            title: 'verdiepingsvloer - bouwmuur',
            type: 'wbouw',
            coordinates: { x: 197, y: 224 },
            selectable: false,
          },
          {
            id: 305,
            title: 'verdiepingsvloer - bovenaansluiting langsgevel (uitkragende vloer)',
            type: 'wbouw',
            coordinates: { x: 54, y: 178 },
            selectable: false,
          },
          {
            id: 306,
            title: 'verdiepingsvloer - onderaansluiting langsgevel (uitkragende vloer)',
            type: 'wbouw',
            coordinates: { x: 14, y: 171 },
            selectable: false,
          },
          {
            id: 307,
            title: 'verdiepingsvloer - kopgevel (uitkragende vloer)',
            type: 'wbouw',
            coordinates: { x: 95, y: 198 },
            selectable: false,
          },
          {
            id: 308,
            title: 'aansluiting gevel dakopbouw op bestaande dakvloer',
            type: 'wbouw',
            coordinates: { x: 278, y: 148 },
            selectable: false,
          },
          {
            id: 309,
            title: 'aansluiting bouwmuur dakopbouw op bestaande dakvloer',
            type: 'wbouw',
            coordinates: { x: 282, y: 128 },
            selectable: false,
          },
          {
            id: 310,
            title: 'verdiepingsvloer - binnenwand',
            type: 'wbouw',
            coordinates: { x: 283, y: 224 },
            selectable: false,
          },
          {
            id: 311,
            title: 'verdiepingsvloer - stalen ligger',
            type: 'wbouw',
            coordinates: { x: 339, y: 216 },
            selectable: false,
          },
          {
            id: 312,
            title: 'verdiepingsvloer - langsgevel met balkon',
            type: 'wbouw',
            coordinates: { x: 193, y: 241 },
            selectable: false,
          },
          {
            id: 313,
            title: 'verdiepingsvloer - langsgevel met balkon aansluiting console 3D',
            type: 'wbouw',
            coordinates: { x: 248, y: 274 },
            selectable: false,
          },
        ],
      },
      {
        id: 4,
        title: 'Schuin dak en uitbouw',
        image: '/visual_facets/w_schuin_dak_en_uitbouw.svg',
        positions: [
          {
            id: 401,
            title: 'hellend dak - langsgevel (dakgoot)',
            type: 'wbouw',
            coordinates: { x: 216, y: 203 },
            selectable: false,
          },
          {
            id: 402,
            title: 'hellend dak - bouwmuur',
            type: 'wbouw',
            coordinates: { x: 204, y: 133 },
            selectable: false,
          },
          {
            id: 403,
            title: 'hellend dak - kopgevel',
            type: 'wbouw',
            coordinates: { x: 287, y: 174 },
            selectable: false,
          },
          {
            id: 404,
            title: 'hellend dak - hellend dak (nokdetail)',
            type: 'wbouw',
            coordinates: { x: 277, y: 114 },
            selectable: false,
          },
          {
            id: 405,
            title: 'hellend dak - kopgevel (opgaand werk)',
            type: 'wbouw',
            coordinates: { x: 128, y: 89 },
            selectable: false,
          },
          {
            id: 407,
            title: 'hellend dak - bouwmuur (nokdetail)',
            type: 'wbouw',
            coordinates: { x: 102, y: 36 },
            selectable: false,
          },
          {
            id: 417,
            title: 'plat dak - langsgevel aanbouw',
            type: 'wbouw',
            coordinates: { x: 181, y: 289 },
            selectable: false,
          },
          {
            id: 434,
            title: 'hellend dak (uitbouw) - langsgevel',
            type: 'wbouw',
            coordinates: { x: 124, y: 203 },
            selectable: false,
          },
          {
            id: 435,
            title: 'plat dak (uitbouw) - langsgevel',
            type: 'wbouw',
            coordinates: { x: 211, y: 270 },
            selectable: false,
          },
          {
            id: 436,
            title: 'hellend dak - binnenwand',
            type: 'wbouw',
            coordinates: { x: 160, y: 112 },
            selectable: false,
          },
          {
            id: 441,
            title: 'hellend dak - knieschot',
            type: 'wbouw',
            coordinates: { x: 235, y: 171 },
            selectable: false,
          },
          {
            id: 442,
            title: 'hellend dak - borstwering',
            type: 'wbouw',
            coordinates: { x: 44, y: 103 },
            selectable: false,
          },
        ],
      },
      {
        id: 5,
        title: 'Plat dak',
        image: '/visual_facets/w_plat_dak.svg',
        positions: [
          {
            id: 409,
            title: 'plat dak - langsgevel',
            type: 'wbouw',
            coordinates: { x: 242, y: 217 },
            selectable: false,
          },
          {
            id: 410,
            title: 'plat dak - langsgevel met kozijn',
            type: 'wbouw',
            coordinates: { x: 204, y: 204 },
            selectable: false,
          },
          {
            id: 411,
            title: 'plat dak - langsgevel dakoverstek',
            type: 'wbouw',
            coordinates: { x: 131, y: 180 },
            selectable: false,
          },
          {
            id: 418,
            title: 'plat dak - bouwmuur',
            type: 'wbouw',
            coordinates: { x: 197, y: 187 },
            selectable: false,
          },
          {
            id: 419,
            title: 'plat dak - kopgevel',
            type: 'wbouw',
            coordinates: { x: 285, y: 212 },
            selectable: false,
          },
        ],
      },
      {
        id: 6,
        title: 'Dakdoorbrekingen',
        image: '/visual_facets/w_dak_overig_1.svg',
        positions: [
          {
            id: 408,
            title: 'hellend dak - dakdoorvoer',
            type: 'wbouw',
            coordinates: { x: 115, y: 61 },
            selectable: false,
          },
          {
            id: 412,
            title: 'hellend dak - onderaansluiting zonnecollector',
            type: 'wbouw',
            coordinates: { x: 174, y: 105 },
            selectable: false,
          },
          {
            id: 413,
            title: 'hellend dak - zijaansluiting zonnecollector',
            type: 'wbouw',
            coordinates: { x: 153, y: 84 },
            selectable: false,
          },
          {
            id: 414,
            title: 'hellend dak - bovenaansluiting zonnecollector',
            type: 'wbouw',
            coordinates: { x: 136, y: 94 },
            selectable: true,
          },
          {
            id: 415,
            title: 'plat dak - doorvoer',
            type: 'wbouw',
            coordinates: { x: 255, y: 85 },
            selectable: false,
          },
          {
            id: 425,
            title: 'dakkapel - onderaansluiting dakkapel met hellend dak',
            type: 'wbouw',
            coordinates: { x: 86, y: 216 },
            selectable: false,
          },
          {
            id: 426,
            title: 'dakkapel - zijaansluiting dakkapel met hellend dak',
            type: 'wbouw',
            coordinates: { x: 138, y: 236 },
            selectable: false,
          },
          {
            id: 427,
            title: 'dakkapel - bovenaansluiting dakkapel met hellend dak',
            type: 'wbouw',
            coordinates: { x: 142, y: 167 },
            selectable: false,
          },
          {
            id: 428,
            title: 'dakkapel - dakrand',
            type: 'wbouw',
            coordinates: { x: 91, y: 186 },
            selectable: false,
          },
          {
            id: 429,
            title: 'dakkapel - aansluiting kozijn op zijwang',
            type: 'wbouw',
            coordinates: { x: 72, y: 173 },
            selectable: false,
          },
          {
            id: 430,
            title: 'dakkapel - aansluiting dak op zijwang',
            type: 'wbouw',
            coordinates: { x: 136, y: 216 },
            selectable: false,
          },
          {
            id: 431,
            title: 'hellend dak - bovenaansluiting dakvenster',
            type: 'wbouw',
            coordinates: { x: 173, y: 166 },
            selectable: false,
          },
          {
            id: 432,
            title: 'hellend dak - zijaansluiting dakvenster',
            type: 'wbouw',
            coordinates: { x: 196, y: 185 },
            selectable: false,
          },
          {
            id: 433,
            title: 'hellend dak - onderaansluiting dakvenster',
            type: 'wbouw',
            coordinates: { x: 210, y: 182 },
            selectable: false,
          },
          {
            id: 437,
            title: 'plat dak - lichtkoepel',
            type: 'wbouw',
            coordinates: { x: 311, y: 221 },
            selectable: false,
          },
          {
            id: 438,
            title: 'hellend dak - onderaansluiting schoorsteen',
            type: 'wbouw',
            coordinates: { x: 60, y: 103 },
            selectable: false,
          },
          {
            id: 439,
            title: 'hellend dak - zijaansluiting schoorsteen',
            type: 'wbouw',
            coordinates: { x: 76, y: 104 },
            selectable: false,
          },
          {
            id: 440,
            title: 'hellend dak - bovenaansluiting schoorsteen',
            type: 'wbouw',
            coordinates: { x: 76, y: 90 },
            selectable: false,
          },
          {
            id: 443,
            title: 'plat dak - bevestiging systemen',
            type: 'wbouw',
            coordinates: { x: 264, y: 113 },
            selectable: false,
          },
        ],
      },
      {
        id: 7,
        title: 'Dak uitzonderingen',
        image: '/visual_facets/w_dak_overig_2.svg',
        positions: [
          {
            id: 406,
            title: 'hellend dak - bouwmuur (zakgoot)',
            type: 'wbouw',
            coordinates: { x: 239, y: 204 },
            selectable: false,
          },
          {
            id: 416,
            title: 'hellend dak - langsgevel (nokaansluiting)',
            type: 'wbouw',
            coordinates: { x: 297, y: 138 },
            selectable: false,
          },
          {
            id: 421,
            title: 'hellend dak - hellend dak (kilkeperdetail)',
            type: 'wbouw',
            coordinates: { x: 144, y: 116 },
            selectable: false,
          },
          {
            id: 422,
            title: 'hellend dak - hellend dak (hoekkeperdetail)',
            type: 'wbouw',
            coordinates: { x: 92, y: 132 },
            selectable: false,
          },
          {
            id: 423,
            title: 'hellend dak - onderaansluiting op plat dak',
            type: 'wbouw',
            coordinates: { x: 189, y: 152 },
            selectable: false,
          },
          {
            id: 424,
            title: 'hellend dak - bovenaansluiting op plat dak',
            type: 'wbouw',
            coordinates: { x: 189, y: 183 },
            selectable: false,
          },
        ],
      },
    ],
  },
  {
    id: 2,
    title: 'Woongebouw',
    assets: [
      {
        id: 1,
        title: 'Fundering',
        image: '/visual_facets/wg_fundering.svg',
        positions: [
          {
            id: 111,
            title: 'fundering - bouwmuur ter plaatse van onverwarmde zone',
            type: 'wbouw',
            coordinates: { x: 240, y: 268 },
            selectable: false,
          },
          {
            id: 112,
            title: 'fundering ter plaatse van loggia evenwijdig aan gevel, onderkant kozijn',
            type: 'wbouw',
            coordinates: { x: 127, y: 266 },
            selectable: false,
          },
          {
            id: 113,
            title: 'fundering ter plaatse van loggia haaks op de gevel, onderkant kozijn',
            type: 'wbouw',
            coordinates: { x: 91, y: 265 },
            selectable: false,
          },
          {
            id: 151,
            title: 'fundering - kopgevel',
            type: 'wbouw',
            coordinates: { x: 217, y: 277 },
            selectable: false,
          },
          {
            id: 152,
            title: 'fundering - langsgevel ter plaatse van deur',
            type: 'wbouw',
            coordinates: { x: 52, y: 266 },
            selectable: false,
          },
          {
            id: 153,
            title: 'vloer boven kelder - langsgevel',
            type: 'wbouw',
            coordinates: { x: 264, y: 243 },
            selectable: false,
          },
          {
            id: 154,
            title: 'vloer boven doorlopende kelder - langsgevel ter plaatse van de deur',
            type: 'wbouw',
            coordinates: { x: 186, y: 275 },
            selectable: false,
          },
          {
            id: 155,
            title: 'vloer boven doorlopende kelder - langsgevel',
            type: 'wbouw',
            coordinates: { x: 167, y: 272 },
            selectable: false,
          },
        ],
      },
      {
        id: 2,
        title: 'Vloerongebonden',
        image: '/visual_facets/wg_vloerongebonden.svg',
        positions: [
          {
            id: 222,
            title: 'kopgevel - langsgevel met zijkant kozijn',
            type: 'wbouw',
            coordinates: { x: 104, y: 127 },
            selectable: false,
          },
          {
            id: 223,
            title: 'loggia binnenhoek',
            type: 'wbouw',
            coordinates: { x: 133, y: 122 },
            selectable: false,
          },
          {
            id: 224,
            title: 'kozijnaansluiting - bouwmuur ter plaatse van loggia',
            type: 'wbouw',
            coordinates: { x: 180, y: 132 },
            selectable: false,
          },
          {
            id: 251,
            title: 'langsgevel - kopgevel',
            type: 'wbouw',
            coordinates: { x: 43, y: 189 },
            selectable: false,
          },
          {
            id: 252,
            title: 'langsgevel - bouwmuur',
            type: 'wbouw',
            coordinates: { x: 65, y: 119 },
            selectable: false,
          },
          {
            id: 253,
            title: 'bouwmuur, onverwarmde zone binnenhoek',
            type: 'wbouw',
            coordinates: { x: 265, y: 208 },
            selectable: false,
          },
          {
            id: 254,
            title: 'bouwmuur, langsgevel ter plaatse van balkon',
            type: 'wbouw',
            coordinates: { x: 180, y: 202 },
            selectable: false,
          },
          {
            id: 255,
            title: 'kopgevel - langsgevel ter plaatse van balkon',
            type: 'wbouw',
            coordinates: { x: 66, y: 188 },
            selectable: false,
          },
          {
            id: 256,
            title: 'bouwmuur - langsgevel ter plaatse van balkon binnenhoek',
            type: 'wbouw',
            coordinates: { x: 133, y: 192 },
            selectable: false,
          },
          // {
          //   id: 377,
          //   title: 'Hellend dak - zijaansluiting dakvenster',
          //   type: 'wbouw',
          //   coordinates: { x: 241, y: 269 },
          //   selectable: false,
          // },
          // {
          //   id: 378,
          //   title: 'Hellend dak - zijaansluiting dakvenster',
          //   type: 'wbouw',
          //   coordinates: { x: 285, y: 199 },
          //   selectable: false,
          // },
          // {
          //   id: 379,
          //   title: 'Hellend dak - zijaansluiting dakvenster',
          //   type: 'wbouw',
          //   coordinates: { x: 285, y: 268 },
          //   selectable: false,
          // },
          // {
          //   id: 380,
          //   title: 'Hellend dak - zijaansluiting dakvenster',
          //   type: 'wbouw',
          //   coordinates: { x: 241, y: 131 },
          //   selectable: false,
          // },
          // {
          //   id: 381,
          //   title: 'Hellend dak - zijaansluiting dakvenster',
          //   type: 'wbouw',
          //   coordinates: { x: 101, y: 128 },
          //   selectable: false,
          // },
          // {
          //   id: 382,
          //   title: 'Hellend dak - zijaansluiting dakvenster',
          //   type: 'wbouw',
          //   coordinates: { x: 129, y: 129 },
          //   selectable: false,
          // },
          // {
          //   id: 383,
          //   title: 'Hellend dak - zijaansluiting dakvenster',
          //   type: 'wbouw',
          //   coordinates: { x: 132, y: 198 },
          //   selectable: false,
          // },
          // {
          //   id: 384,
          //   title: 'Hellend dak - zijaansluiting dakvenster',
          //   type: 'wbouw',
          //   coordinates: { x: 10, y: 20 },
          //   selectable: false,
          // },
        ],
      },
      {
        id: 3,
        title: 'Vloer',
        image: '/visual_facets/wg_vloer_huidig_2.svg',
        positions: [
          {
            id: 353,
            title: 'verdiepingsvloer - langsgevel met balkon/galerij, boven onverwarmde ruimte',
            type: 'wbouw',
            coordinates: { x: 156, y: 240 },
            selectable: false,
          },
          {
            id: 354,
            title:
              'verdiepingsvloer - langsgevel met balkon/galerij, boven onverwarmde ruimte, t.p.v. deur',
            type: 'wbouw',
            coordinates: { x: 196, y: 249 },
            selectable: false,
          },
          {
            id: 354,
            title:
              'verdiepingsvloer - langsgevel met balkon/galerij, boven onverwarmde ruimte, t.p.v. deur',
            type: 'wbouw',
            coordinates: { x: 196, y: 249 },
            selectable: false,
          },
          {
            id: 360,
            title: 'verdiepingsvloer - langsgevel boven onverwarmde ruimte',
            type: 'wbouw',
            coordinates: { x: 233, y: 260 },
            selectable: false,
          },
          {
            id: 363,
            title: 'verdiepingsvloer - kopgevel boven onverwarmde ruimte',
            type: 'wbouw',
            coordinates: { x: 309, y: 252 },
            selectable: false,
          },
          {
            id: 364,
            title: 'verdiepingsvloer - deur tussen trappenhuis en galerij',
            type: 'wbouw',
            coordinates: { x: 109, y: 154 },
            selectable: false,
          },
          {
            id: 365,
            title: 'verdiepingsvloer - deur tussen trappenhuis en woning',
            type: 'wbouw',
            coordinates: { x: 149, y: 147 },
            selectable: false,
          },
          {
            id: 371,
            title: 'verdiepingsvloer - bouwmuur t.p.v. trappenhuis',
            type: 'wbouw',
            coordinates: { x: 177, y: 142 },
            selectable: false,
          },
          {
            id: 372,
            title: 'verdiepingsvloer - schacht',
            type: 'wbouw',
            coordinates: { x: 198, y: 138 },
            selectable: false,
          },
          {
            id: 375,
            title: 'verdiepingsvloer boven onverwarmde ruimte - bouwmuur',
            type: 'wbouw',
            coordinates: { x: 178, y: 226 },
            selectable: false,
          },
          {
            id: 376,
            title: 'verdiepingsvloer - langsgevel met boven- en onderkant kozijn',
            type: 'wbouw',
            coordinates: { x: 55, y: 154 },
            selectable: false,
          },
        ],
      },
      {
        id: 4,
        title: 'Vloer',
        image: '/visual_facets/wg_vloer_1.svg',
        positions: [
          {
            id: 351,
            title: 'verdiepingsvloer - langsgevel met balkon/galerij',
            type: 'wbouw',
            coordinates: { x: 193, y: 171 },
            selectable: false,
          },
          {
            id: 352,
            title: 'verdiepingsvloer - langsgevel met balkon/galerij ter plaatse van deur',
            type: 'wbouw',
            coordinates: { x: 162, y: 166 },
            selectable: false,
          },
          {
            id: 355,
            title: 'verdiepingsvloer - langsgevel met balkon/galerij boven binnenruimte',
            type: 'wbouw',
            coordinates: { x: 101, y: 236 },
            selectable: false,
          },
          {
            id: 356,
            title:
              'verdiepingsvloer - langsgevel met balkon/galerij boven binnenruimte, t.p.v. deur',
            type: 'wbouw',
            coordinates: { x: 59, y: 230 },
            selectable: false,
          },
          {
            id: 357,
            title: 'verdiepingsvloer - langsgevel en balkon/galerij boven binnenruimte',
            type: 'wbouw',
            coordinates: { x: 73, y: 248 },
            selectable: false,
          },
          {
            id: 358,
            title: 'verdiepingsvloer - onderaansluiting langsgevel (uitkragende vloer)',
            type: 'wbouw',
            coordinates: { x: 21, y: 160 },
            selectable: false,
          },
          {
            id: 359,
            title: 'verdiepingsvloer - bovenaansluiting langsgevel (uitkragende vloer)',
            type: 'wbouw',
            coordinates: { x: 62, y: 158 },
            selectable: false,
          },
          {
            id: 361,
            title: 'verdiepingsvloer - kopgevel (uitkragende vloer)',
            type: 'wbouw',
            coordinates: { x: 346, y: 152 },
            selectable: false,
          },
          {
            id: 362,
            title: 'verdiepingsvloer - kopgevel met bovenkant kozijn',
            type: 'wbouw',
            coordinates: { x: 233, y: 255 },
            selectable: false,
          },
          {
            id: 366,
            title: 'verdiepingsvloer - kopgevel',
            type: 'wbouw',
            coordinates: { x: 284, y: 239 },
            selectable: false,
          },
          {
            id: 367,
            title: 'verdiepingsvloer - bouwmuur',
            type: 'wbouw',
            coordinates: { x: 184, y: 231 },
            selectable: false,
          },
          {
            id: 368,
            title: 'verdiepingsvloer - langsgevel',
            type: 'wbouw',
            coordinates: { x: 195, y: 255 },
            selectable: false,
          },
          {
            id: 369,
            title: 'verdiepingsvloer - 3D aansluiting vloer - opgaand werk en kozijn',
            type: 'wbouw',
            coordinates: { x: 140, y: 162 },
            selectable: false,
          },
          {
            id: 370,
            title: 'verdiepingsvloer - binnenwand',
            type: 'wbouw',
            coordinates: { x: 228, y: 230 },
            selectable: false,
          },
          {
            id: 373,
            title: 'verdiepingsvloer - langsgevel met bovenkant kozijn',
            type: 'wbouw',
            coordinates: { x: 168, y: 251 },
            selectable: false,
          },
          {
            id: 374,
            title: 'verdiepingsvloer -  langsgevel met bovenkant kozijn (uitkragende vloer)',
            type: 'wbouw',
            coordinates: { x: 95, y: 162 },
            selectable: false,
          },
        ],
      },
      {
        id: 5,
        title: 'Vloer',
        image: '/visual_facets/wg_vloer_2.svg',
        positions: [
          {
            id: 377,
            title:
              'verdiepingsvloer boven onverwarmde zone - bouwmuur ter plaatse van onverwarmde zone',
            type: 'wbouw',
            coordinates: { x: 241, y: 269 },
            selectable: false,
          },
          {
            id: 378,
            title: 'verdiepingsvloer - deur tussen lifthal en onverwarmde zone',
            type: 'wbouw',
            coordinates: { x: 285, y: 200 },
            selectable: false,
          },
          {
            id: 379,
            title:
              'verdiepingsvloer boven onverwarmde zone - deur tussen lifthal en onverwarmde zone',
            type: 'wbouw',
            coordinates: { x: 285, y: 268 },
            selectable: false,
          },
          {
            id: 380,
            title: 'bouwmuur - verdiepingsvloer boven onverwarmde zone binnenhoek',
            type: 'wbouw',
            coordinates: { x: 241, y: 131 },
            selectable: false,
          },
          {
            id: 381,
            title: 'verdiepingsvloer - kozijn ter plaatse van loggia haaks op de gevel',
            type: 'wbouw',
            coordinates: { x: 101, y: 128 },
            selectable: false,
          },
          {
            id: 382,
            title: 'verdiepingsvloer - kozijn ter plaatse van loggia evenwijdig aan gevel',
            type: 'wbouw',
            coordinates: { x: 129, y: 129 },
            selectable: false,
          },
          {
            id: 383,
            title: 'verdiepingsvloer, bouwmuur ter plaatse van loggia',
            type: 'wbouw',
            coordinates: { x: 132, y: 198 },
            selectable: false,
          },
          {
            id: 384,
            title: 'verdiepingsvloer - bouwmuur ter plaatse van onverwarmde zone',
            type: 'wbouw',
            coordinates: { x: 242, y: 200 },
            selectable: false,
          },
        ],
      },
      {
        id: 6,
        title: 'Dak',
        image: '/visual_facets/wg_dak.svg',
        positions: [
          {
            id: 484,
            title: 'plat dak - bouwmuur ter plaatse van onverwarmde zone',
            type: 'wbouw',
            coordinates: { x: 209, y: 136 },
            selectable: false,
          },
          {
            id: 485,
            title: 'plat dak - kozijn ter plaatse van onverwarmde zone',
            type: 'wbouw',
            coordinates: { x: 246, y: 139 },
            selectable: false,
          },
          {
            id: 486,
            title: 'plat dak, kozijn ter plaatse van loggia',
            type: 'wbouw',
            coordinates: { x: 150, y: 112 },
            selectable: false,
          },
          {
            id: 487,
            title: 'plat dak - bouwmuur ter plaatse van loggia',
            type: 'wbouw',
            coordinates: { x: 63, y: 93 },
            selectable: false,
          },
        ],
      },
    ],
  },
  {
    id: 3,
    title: 'Utiliteitsgebouw',
    assets: [
      {
        id: 1,
        title: 'Fundering',
        image: '/visual_facets/u_fundering.svg',
        positions: [
          {
            id: 101,
            title: 'fundering-voorgevel',
            type: 'UBouw',
            coordinates: { x: 143, y: 268 },
            selectable: false,
          },
          {
            id: 102,
            title: 'fundering -  zijgevel',
            type: 'UBouw',
            coordinates: { x: 240, y: 276 },
            selectable: false,
          },
        ],
      },
      {
        id: 2,
        title: 'Vloerongebonden',
        image: '/visual_facets/u_vloerongebonden.svg',
        positions: [
          {
            id: 201,
            title: 'voorgevel – zijgevel',
            type: 'UBouw',
            coordinates: { x: 143, y: 187 },
            selectable: false,
          },
          {
            id: 202,
            title: 'voorgevel met kozijn – zijgevel met kozijn',
            type: 'UBouw',
            coordinates: { x: 143, y: 216 },
            selectable: false,
          },
          {
            id: 203,
            title: 'voorgevel  -  draagconstructie',
            type: 'UBouw',
            coordinates: { x: 97, y: 194 },
            selectable: false,
          },
          {
            id: 204,
            title: 'voorgevel met kozijn – draagconstructie',
            type: 'UBouw',
            coordinates: { x: 168, y: 134 },
            selectable: false,
          },
          {
            id: 205,
            title: 'voorgevel – bovenkant kozijn',
            type: 'UBouw',
            coordinates: { x: 70, y: 197 },
            selectable: false,
          },
          {
            id: 206,
            title: 'voorgevel – onderkant kozijn',
            type: 'UBouw',
            coordinates: { x: 69, y: 220 },
            selectable: false,
          },
          {
            id: 207,
            title: 'voorgevel – zijkant kozijn',
            type: 'UBouw',
            coordinates: { x: 78, y: 211 },
            selectable: false,
          },
          {
            id: 208,
            title: 'voorgevel - bovenkant deurkozijn',
            type: 'UBouw',
            coordinates: { x: 312, y: 297 },
            selectable: false,
          },
          {
            id: 209,
            title: 'voorgevel - zijkant deurkozijn',
            type: 'UBouw',
            coordinates: { x: 322, y: 313 },
            selectable: false,
          },
        ],
      },
      {
        id: 3,
        title: 'Vloer',
        image: '/visual_facets/u_vloer.svg',
        positions: [
          {
            id: 301,
            title: 'verdiepingsvloer - voorgevel',
            type: 'UBouw',
            coordinates: { x: 175, y: 174 },
            selectable: false,
          },
          {
            id: 302,
            title: 'overkragende verdiepingsvloer - voorgevel',
            type: 'UBouw',
            coordinates: { x: 94, y: 152 },
            selectable: false,
          },
          {
            id: 303,
            title: 'verdiepingsvloer - voorgevel met kozijn',
            type: 'UBouw',
            coordinates: { x: 123, y: 196 },
            selectable: false,
          },
          {
            id: 304,
            title: 'verdiepingsvloer – zijgevel opgaand werk',
            type: 'UBouw',
            coordinates: { x: 157, y: 112 },
            selectable: false,
          },
          {
            id: 305,
            title: 'verdiepingsvloer -  zijgevel',
            type: 'UBouw',
            coordinates: { x: 259, y: 225 },
            selectable: false,
          },
          {
            id: 306,
            title: 'verdiepingsvloer boven onverwarmde ruikte - voorgevel met kozijn',
            type: 'UBouw',
            coordinates: { x: 166, y: 248 },
            selectable: false,
          },
          {
            id: 307,
            title: 'verdiepingsvloer boven onverwarmde ruimte – voorgevel opgaand werk',
            type: 'UBouw',
            coordinates: { x: 193, y: 263 },
            selectable: false,
          },
          {
            id: 308,
            title: 'verdiepingsvloer - scheidingswand',
            type: 'UBouw',
            coordinates: { x: 177, y: 214 },
            selectable: false,
          },
        ],
      },
      {
        id: 4,
        title: 'Dak',
        image: '/visual_facets/u_dak.svg',
        positions: [
          {
            id: 401,
            title: 'plat dak - voorgevel',
            type: 'UBouw',
            coordinates: { x: 81, y: 139 },
            selectable: true,
          },
          {
            id: 402,
            title: 'plat dak - voorgevel met kozijn',
            type: 'UBouw',
            coordinates: { x: 51, y: 121 },
            selectable: true,
          },
          {
            id: 403,
            title: 'plat dak - zijgevel',
            type: 'UBouw',
            coordinates: { x: 98, y: 90 },
            selectable: true,
          },
          {
            id: 405,
            title: 'plat dak - binnenwand',
            type: 'UBouw',
            coordinates: { x: 186, y: 97 },
            selectable: true,
          },
          {
            id: 406,
            title: 'voorgevel - gebogen dak',
            type: 'UBouw',
            coordinates: { x: 273, y: 237 },
            selectable: true,
          },
          {
            id: 407,
            title: 'nok -  gebogen dak',
            type: 'UBouw',
            coordinates: { x: 300, y: 211 },
            selectable: true,
          },
          {
            id: 408,
            title: 'zijgevel - gebogen dak',
            type: 'UBouw',
            coordinates: { x: 298, y: 233 },
            selectable: true,
          },
          {
            id: 409,
            title: 'plat dak - langsgevel',
            type: 'UBouw',
            coordinates: { x: 337, y: 254 },
            selectable: true,
          },
          {
            id: 410,
            title: 'plat dak - langsgevel met kozijn',
            type: 'UBouw',
            coordinates: { x: 160, y: 140 },
            selectable: true,
          },
          {
            id: 412,
            title: 'hellend dak - bevestiging systemen',
            type: 'UBouw',
            coordinates: { x: 273, y: 215 },
            selectable: true,
          },
        ],
      },
    ],
  },
];

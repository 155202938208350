import { create } from 'zustand';

export const searchStore = create((set) => ({
  searchState: {
    facets: {
      single: [],
      multi: [],
    },
    sortOption: '',
    page: '',
    view: '',
    query: '',
    bucket: '',
  },
  setSearchState: (searchState) => set({ searchState }),
}));

import { usePositionContext } from '@search/Facets/FacetComponents/Position';
import clsx from 'clsx';
import { useRouter } from 'next/router';

function Position({ position }) {
  const { setShowModal, selectedPosition, setSelectedPosition } = usePositionContext();
  const router = useRouter();

  if (!position) {
    return null;
  }

  const transform = `translate(${position.coordinates.x}px, ${position.coordinates.y}px)`;

  function onClick() {
    if (selectedPosition === position.id + ' ' + position.title) {
      resetFilter();
    } else {
      setShowModal(false);

      const updatedQuery = {
        DocumentType: router.query.DocumentType || 'Referentiedetail',
        ReferenceDetailPosition: `${position.id} ${position.title}`,
        ...(position.type === 'UBouw' && {
          Status: null,
          ReferenceDetailMethod: null,
        }),
        ...router.query,
      };

      router.replace(
        {
          pathname: router.pathname,
          query: updatedQuery,
        },
        undefined,
        { shallow: false },
      );
      setSelectedPosition(position.id + ' ' + position.title);
    }
  }

  function resetFilter() {
    setSelectedPosition(null);
    // eslint-disable-next-line no-unused-vars
    const { ReferenceDetailPosition, ...restQuery } = router.query;
    delete restQuery.ReferenceDetailPosition;

    router.replace(
      {
        pathname: router.pathname,
        query: restQuery,
      },
      undefined,
      { shallow: false },
    );
  }

  return (
    <div className="absolute">
      <button
        className={clsx(
          selectedPosition === position.id + ' ' + position.title
            ? 'border-accent '
            : position.selectable
              ? 'border-primary-dark'
              : 'border-gray-400',
          'group z-[5] size-5 transform-gpu cursor-pointer rounded-full border-[5px] bg-white pt-2.5',
        )}
        style={{ transform }}
        onClick={onClick}
        title={`${position?.id} - ${position?.title}`}
        disabled={!position.selectable && selectedPosition !== position.id + ' ' + position.title}
      />
    </div>
  );
}

export default Position;

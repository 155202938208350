import { searchStore } from '@context/searchStore';
import Button from '@elements/buttons/Button';
import { classNames } from '@helpers/classNames';
import { createSearchQuery } from '@helpers/searchQuery';
import { useRouter } from 'next/router';
import Pagino from 'pagino';
import { useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

export default function Pagination(props) {
  const { count, loading } = props;
  const [pages, setPages] = useState([]);
  const router = useRouter();

  // Gets the current search store
  const searchState = searchStore((state) => state.searchState);

  const pagino = useMemo(() => {
    const pagino = new Pagino({
      siblingCount: 1,
      boundaryCount: 1,
      showFirst: false,
      showLast: false,
      onChange: () => setPages(pagino.getPages()),
    });

    // Switching between card and thumbnail view could cause a discrepancy in page count
    // This fixes the problem by ensuring that the current page cannot be greater than the max page count
    if (searchState.page > count) {
      const query = createSearchQuery('page', count, router);
      router.push(query.query, query.visibleQuery, query.scroll);
    }

    pagino.setCount(count);
    pagino.setPage(parseInt(searchState.page));

    return pagino;
  }, [searchState.page, count]);

  function handlePageClick(page) {
    let currentPage = searchState.page;
    switch (page) {
      case 'next':
        currentPage++;
        break;
      case 'previous':
        currentPage--;
        break;
      default:
        currentPage = page;
    }
    const query = createSearchQuery('page', currentPage, router);
    router.push(query.query, query.visibleQuery, query.scroll);
  }

  const createPage = (page) => {
    switch (page) {
      case 'start-ellipsis':
        return <button key={page}>...</button>;
      case 'end-ellipsis':
        return <button key={page}>...</button>;
      case 'previous':
        return (
          <Button
            icon="arrow-left"
            key={page}
            overrideClass={classNames('h-10 w-10', !(pagino.page > 1) && 'invisible')}
            onClick={() => handlePageClick(page)}
          />
        );
      case 'next':
        return (
          <Button
            icon="arrow-right"
            key={page}
            overrideClass={classNames('h-10 w-10', !(pagino.page < count) && 'invisible')}
            onClick={() => handlePageClick(page)}
          />
        );
    }

    return (
      <button
        className={classNames(
          'flex items-center',
          page == pagino.page && 'flex items-center font-bold',
        )}
        onClick={() => handlePageClick(page)}
      >
        {page}
      </button>
    );
  };

  return (
    <div>
      <nav className="flex items-center justify-center gap-6 font-secondary font-light leading-6">
        {loading ? (
          <div className="w-96 text-5xl">
            <Skeleton />
          </div>
        ) : (
          pages.map((page) => {
            return <div key={page}>{createPage(page)}</div>;
          })
        )}
      </nav>
    </div>
  );
}

import { searchStore } from '@context/searchStore';
import Icon from '@elements/Icon';
import { useRouter } from 'next/router';

export default function LastSearchTerm() {
  const searchState = searchStore((state) => state.searchState);
  const router = useRouter();

  function handleClick() {
    const url = {
      pathname: router.pathname,
    };
    router.push(url, undefined, { scroll: false });
  }
  return (
    searchState.query && (
      <div className="flex items-center justify-between gap-3 border border-primary-light bg-accent-3 px-4 py-3">
        <div className="line-clamp-2 font-secondary font-light leading-5">
          <q>{searchState.query} </q>
        </div>
        <button onClick={handleClick}>
          <Icon iconName="x" size="xs" color="text-accent" />
        </button>
      </div>
    )
  );
}

import { facetOrderByDocumentType } from './facetOrderByDocumentType';

const { facetConfig } = require('@config/facetConfig');
const { sortArrayByArray } = require('./sortArrayByArray');

/**
 * Function used to convert Elastic Search aggregations to facets used in the web application.
 * @param {Object} aggregations - Aggregations object from Elastic Search to be converted to facets
 */

const categoryDisplayNameMapping = {
  Status: 'Status',
  Category: 'Categorie',
  SubCategory: 'Subcategorie',
  DocumentType: 'Documentsoort',
  Publisher: 'Kennisleverancier',

  ReferenceDetailPosition: 'Positie',
  ReferenceDetailLayer: 'Layer',
  ReferenceDetailType: 'Gebouwtype',
  ReferenceDetailLevel: 'Niveau',
  ReferenceDetailBearingStructure: 'Draagstructuur',
  ReferenceDetailFrontFacadeType: 'Langsgeveltype',
  ReferenceDetailSideFacadeType: 'Kopgeveltype',
  ReferenceDetailFacadeType: 'Geveltype',
  ReferenceDetailMethod: 'Rekenmethode',
  ReferenceDetailBearingStructureType: 'Bouwmuurtype',
  ReferenceDetailFloorType: 'Vloertype',
};

let documentType = '';

function getFacetsFromAggregations(aggregations) {
  let facets = [];
  for (const [key, value] of Object.entries(aggregations)) {
    const category = key.split('.')[0];
    let type;
    type = facetConfig.single.includes(category) ? 'radio' : 'checkbox';
    if (category === 'ReferenceDetailPosition') {
      type = 'position';
    }
    const facetValues = value.buckets.map(({ key, doc_count }) => ({
      label: key,
      hits: doc_count,
    }));

    if (category === 'DocumentType' && value.buckets.length > 0) {
      documentType = value.buckets[0].key;
    }

    const displayName = categoryDisplayNameMapping[category] || category;

    facets.push({
      category: category,
      displayName: displayName,
      type: type,
      values: facetValues,
    });
  }

  const orderedFacets = sortArrayByArray(
    facets,
    facetOrderByDocumentType(documentType),
    'category',
  );
  return orderedFacets;
}

export { getFacetsFromAggregations };

import { usePositionContext } from '@search/Facets/FacetComponents/Position';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import AssetTabs from './AssetTabs';
import PositionContainer from './PositionContainer';

function PositionSelector() {
  const { selectedAsset, setAsset, selectedTab, setSelectedPosition, config } =
    usePositionContext();

  const router = useRouter();

  useEffect(() => {
    setAsset(config[selectedTab].assets[selectedAsset]);
  }, [selectedTab, selectedAsset]);

  function resetFilter() {
    setSelectedPosition(null);
    // eslint-disable-next-line no-unused-vars
    const { ReferenceDetailPosition, ...restQuery } = router.query;
    delete restQuery.ReferenceDetailPosition;

    router.replace(
      {
        pathname: router.pathname,
        query: restQuery,
      },
      undefined,
      { shallow: false },
    );
  }

  return (
    <div className="max-w-md md:max-w-lg lg:max-w-4xl">
      {router.query.ReferenceDetailPosition ? (
        <button
          className="pr-2 font-secondary font-light text-accent underline"
          onClick={resetFilter}
        >
          herstel filter
        </button>
      ) : (
        <div className="h-6" />
      )}
      <div className="flex flex-col gap-8">
        <PositionContainer />
        <AssetTabs />
      </div>
    </div>
  );
}

export default PositionSelector;

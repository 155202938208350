import { hasKeyValuePair } from './hasKeyValuePair';

export const defaults = {
  Status: 'Actueel',
  view: 'list',
  page: '1',
  sort: 'Relevantie',
};

export function getDefaults(documentType) {
  if (documentType == 'Referentiedetail') {
    return { ...defaults, ReferenceDetailMethod: 'NTA8800' };
  } else return defaults;
}

export function createSearchQuery(key, value, router) {
  const query = {
    pathname: router.pathname,
    query: { ...router.query, [key]: value },
  };
  const visibleQuery = {
    pathname: router.pathname,
    query: { ...createVisibleQuery({ ...router.query, [key]: value }) },
  };
  const scroll = { scroll: false };
  return { query, visibleQuery, scroll };
}

export function createVisibleQuery(query) {
  let visibleQuery = {};
  for (const [key, value] of Object.entries(query)) {
    if (value != '' && value && !hasKeyValuePair(getDefaults(query['DocumentType']), key, value)) {
      visibleQuery[key] = value;
    }
  }
  return visibleQuery;
}

export function setDefaultQueryValues(query) {
  for (const [key, value] of Object.entries(getDefaults(query['DocumentType']))) {
    if (query[key] == null) {
      query[key] = value;
    } else if (query[key] == '*') {
      query[key] = '';
    }
  }
  return query;
}

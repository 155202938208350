import { usePositionContext } from '@search/Facets/FacetComponents/Position';
import { useEffect, useState } from 'react';
import Position from './Position';

function RenderPositions() {
  const { asset, positionsFacet, selectedPosition } = usePositionContext();
  const positions = asset.positions;

  const [updatedPositions, setUpdatedPositions] = useState([]);

  function updateConfig(positions, positionsFacet, selectedPosition = null) {
    return positions.map((position) => ({
      ...position,
      selectable:
        positionsFacet.some((facet) => facet.label === position.id + ' ' + position.title) &&
        !selectedPosition,
      selected: position.id + ' ' + position.title === selectedPosition,
    }));
  }

  useEffect(() => {
    const updated = updateConfig(positions, positionsFacet, selectedPosition);
    setUpdatedPositions(updated);
  }, [positionsFacet, positions, selectedPosition]);
  return (
    <div className="absolute inset-0">
      {updatedPositions.map((position, index) => (
        <Position key={index} position={position} />
      ))}
    </div>
  );
}

export default RenderPositions;

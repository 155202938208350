import Icon from '@elements/Icon';
import FavoriteButton from '@elements/buttons/FavoriteButton';
import NextLink from '@elements/links/NextLink';
import { usePlausible } from 'next-plausible';
import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import SlideOut from '../SlideOut';

export default function SearchResultCard({
  documentId,
  title,
  hit,
  documentType,
  publicationDate,
  isFavorite,
  thumbnailUrl,
  publisher,
  loading,
}) {
  const [showModal, setShowModal] = useState(false);

  const plausibleEvent = usePlausible();

  const onClickModal = () => {
    if (showModal) {
      setShowModal(false);
    } else {
      setShowModal(true);
    }
  };

  return (
    <div className="relative w-full rounded-lg shadow-lg">
      <SlideOut setShowModal={setShowModal} showModal={showModal} publisher={publisher} />

      <NextLink
        onClick={() =>
          plausibleEvent('Zoekresultaat: Klik', { props: { documentId: documentId?.toString() } })
        }
        href={`/reader/${documentId?.toString()}`}
      >
        <div className="absolute inset-0 z-0 size-full" />
      </NextLink>

      <div className="flex flex-row">
        <div className="w-1/5 rounded-l-lg bg-accent-3 px-2.5 py-8 md:w-1/6 md:px-3.5 lg:px-6">
          {/* {data.hasBadge && <SearchResultBadge searchResultBadge={data.searchResultBadge} />} */}
          {!loading && (
            <div className="block w-full shadow-xl">
              <img
                src={thumbnailUrl}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = '/issodefault.png';
                }}
                alt="Thumbnail"
              />
            </div>
          )}
        </div>
        <div className="flex w-4/5 flex-col gap-4 px-4 py-6 md:px-6">
          <div className="hidden justify-between md:flex">
            <div className="flex-row items-center justify-start gap-3 leading-5 md:flex">
              <div className="font-secondary font-light text-primary-dark">
                {loading ? (
                  <div className="w-20 text-2xl">
                    <Skeleton />
                  </div>
                ) : (
                  documentType
                )}
              </div>
              <div className="font-secondary text-cta-secondary-dark">|</div>
              {publisher && (
                <>
                  <button
                    className="z-10 font-secondary font-light text-accent underline"
                    onClick={onClickModal}
                  >
                    {loading ? (
                      <div className="w-8 text-2xl">
                        <Skeleton />
                      </div>
                    ) : (
                      publisher
                    )}
                  </button>
                  <div className="font-secondary text-cta-secondary-dark">|</div>
                </>
              )}

              <div className="font-secondary font-light text-primary-dark">
                {loading ? (
                  <div className="w-20 text-2xl">
                    <Skeleton />
                  </div>
                ) : (
                  publicationDate
                )}
              </div>
            </div>
            <div className="z-10">
              {loading ? (
                <Icon iconName="heart-outline" color="text-primary-dark" />
              ) : (
                <FavoriteButton isFavorite={isFavorite} documentId={documentId?.toString()} />
              )}
            </div>
          </div>
          <div className="md:hidden">
            <div className="flex justify-between">
              <div className="flex flex-col gap-3 sm:flex-row">
                <div className="font-secondary font-light text-primary-dark">
                  {loading ? (
                    <div className="w-7 text-xl">
                      <Skeleton />
                    </div>
                  ) : (
                    documentType
                  )}
                </div>
                <div className="hidden font-secondary text-cta-secondary-dark sm:block">|</div>
                <div
                  className="z-10 font-secondary font-light leading-5 text-accent underline"
                  onClick={onClickModal}
                  role="presentation"
                >
                  {loading ? (
                    <div className="w-7 text-xl">
                      <Skeleton />
                    </div>
                  ) : (
                    publisher
                  )}
                </div>
                <div className="hidden font-secondary text-cta-secondary-dark sm:block">|</div>
              </div>
              <FavoriteButton isFavorite={isFavorite} documentId={documentId?.toString()} />
            </div>
            <div className="pt-1 font-secondary font-light text-primary-dark sm:pt-0">
              {loading ? (
                <div className="w-7 text-xl">
                  <Skeleton />
                </div>
              ) : (
                publicationDate
              )}
            </div>
          </div>
          {/* Search Result Card Content */}
          <h2 className="text-xl text-primary-dark">
            {loading ? (
              <div className="text-4xl">
                <Skeleton />
              </div>
            ) : (
              // Returns the title in HTML format, so that special characters are displayed correctly
              // Special characters are for example the <mark> tags used to show highlighted sections
              <div dangerouslySetInnerHTML={{ __html: title }} />
            )}
          </h2>
          {/* TODO Verify ES response for the element used for hightlights */}
          <div className="font-secondary font-light leading-5 text-primary-dark">
            {loading ? (
              <div className="text-2xl">
                <Skeleton count={4} />
              </div>
            ) : (
              // Returns the hit in HTML format, so that special characters are displayed correctly
              // Special characters are for example the <mark> tags used to show highlighted sections
              <div dangerouslySetInnerHTML={{ __html: hit }} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

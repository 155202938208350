const resultsPerPage = {
  resultCard: 10,
  thumbnail: 12,
};

export function getPageRange(page, view) {
  let maxPage = 0;
  let minPage = 0;
  if (view == 'list') {
    maxPage = page * resultsPerPage.resultCard;
    minPage = maxPage - resultsPerPage.resultCard;
  } else {
    maxPage = page * resultsPerPage.thumbnail;
    minPage = maxPage - resultsPerPage.thumbnail;
  }
  return { minPage, maxPage };
}

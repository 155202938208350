export function facetOrderByDocumentType(documentType) {
  switch (documentType) {
    case 'Referentiedetail':
      return [
        'Status',
        'DocumentType',
        'ReferenceDetailMethod',
        'ReferenceDetailPosition',
        'ReferenceDetailType',
        'Method',
        'Publisher',
        'Category',
        'SubCategory',
      ];

    default:
      ['Status', 'Category', 'SubCategory', 'DocumentType', 'Publisher'];
  }
  return [];
}

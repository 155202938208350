import { RadioGroup } from '@headlessui/react';
import { createSearchQuery } from '@helpers/searchQuery';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';

function RadioIcon({ selected }) {
  return selected ? (
    <div>
      <div className="flex size-6 items-center justify-center rounded-full border border-accent">
        <div className="size-[14px] rounded-full bg-accent" />
      </div>
    </div>
  ) : (
    <div className="size-6 rounded-full border border-cta-secondary-dark p-[11px] group-hover:border-accent" />
  );
}

RadioIcon.propTypes = {
  selected: PropTypes.bool,
};

RadioIcon.defaultProps = {
  selected: false,
};

export default function Radio({ option, loading, facetFamily }) {
  const router = useRouter();

  function handleOnClick(facetFamily, facetValue, router) {
    const query = createSearchQuery(facetFamily, facetValue, router);
    router.push(query.query, query.visibleQuery, query.scroll);
  }

  return (
    <RadioGroup.Option
      className="group text-cta-tertiary-dark hover:text-accent"
      key={option.label}
      value={option}
      onClick={() => handleOnClick(facetFamily, option.label, router)}
    >
      {({ checked }) => (
        <div className="flex items-center justify-between py-2">
          <div className="flex justify-start gap-3">
            {loading ? (
              <div className="w-7 text-2xl">
                <Skeleton />
              </div>
            ) : (
              <RadioIcon selected={checked} />
            )}
            {loading ? (
              <div className="w-48 text-2xl">
                <Skeleton />
              </div>
            ) : (
              <RadioGroup.Label className="font-secondary font-light text-primary-dark">
                {option.label}
              </RadioGroup.Label>
            )}
          </div>
          {loading ? (
            <div className="w-10 text-2xl">
              <Skeleton />
            </div>
          ) : (
            <RadioGroup.Label className="font-secondary font-light text-[#8B8B8B]">
              {option.hits}
            </RadioGroup.Label>
          )}
        </div>
      )}
    </RadioGroup.Option>
  );
}

Radio.propTypes = {
  option: PropTypes.shape({
    label: PropTypes.string,
    hits: PropTypes.number,
  }),
  loading: PropTypes.bool,
  facetFamily: PropTypes.string,
};

Radio.defaultProps = {
  option: {
    label: '',
    hits: 0,
  },
  loading: false,
  facetFamily: '',
};

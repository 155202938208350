import clsx from 'clsx';

function AssetThumbnail({ asset, selectedAsset, setSelectedAsset, setAsset }) {
  function onClick() {
    setSelectedAsset(asset.id - 1);
    setAsset(asset);
  }

  return (
    <button onClick={onClick} className="flex flex-col gap-2">
      <div
        className={clsx(
          asset.id - 1 === selectedAsset && 'border border-accent',
          'size-24 bg-accent-4',
        )}
      >
        <img className="object-cover text-xs" src={asset.image} alt={asset.title} />
      </div>
      <div
        className={clsx(
          'h-8 max-w-[96px] text-left text-xs',
          asset.id - 1 === selectedAsset && 'text-accent',
        )}
      >
        {asset.title}
      </div>
    </button>
  );
}

export default AssetThumbnail;

import FavoriteButton from '@elements/buttons/FavoriteButton';
import NextLink from '@elements/links/NextLink';
import { classNames } from '@helpers/classNames';
import { getDate } from '@helpers/getDate';
import 'animate.css';
import PropTypes from 'prop-types';

const Thumbnail = ({
  documentId,
  title,
  documentType,
  publicationDate,
  isFavorite,
  thumbnailUrl,
  publisher,
  overrideClass,
  setUnfavorite,
  isSetUnfavorite,
}) => {
  return (
    <div className={classNames('relative w-60', overrideClass)}>
      <div className="absolute right-0 top-0 pr-2 pt-2">
        <FavoriteButton
          isFavorite={isFavorite}
          documentId={documentId.toString()}
          setUnfavorite={setUnfavorite}
          isSetUnfavorite={isSetUnfavorite}
        />
      </div>
      <NextLink href={`/reader/${documentId.toString()}`}>
        <div className="grid h-[300px] place-items-center rounded-lg bg-accent-3">
          {/* TODO Check with PO whether Figma or Zeplin has the correct design */}
          <div className="aspect-[2/3] max-h-56 shadow-2xl">
            <img
              src={thumbnailUrl}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/issodefault.png';
              }}
              alt="Thumbnail"
            />
          </div>
        </div>
        <div className="p-4">
          <div className="flex justify-start gap-3 pb-2">
            <p className="font-secondary text-sm leading-4 text-primary-dark">{documentType}</p>
            {publisher && (
              <>
                <div className="font-secondary leading-4 text-cta-secondary-dark">|</div>
                <div className="font-secondary text-sm leading-4 text-accent underline">
                  {publisher}
                </div>
              </>
            )}
          </div>
          <div className="mb-4 line-clamp-2 h-10 max-h-10 font-secondary font-black leading-5 text-primary-dark">
            {/* Returns the title in HTML format, so that special characters are displayed correctly
              Special characters are for example the <mark> tags used to show highlighted sections*/}
            <div dangerouslySetInnerHTML={{ __html: title }} />
          </div>
          <p className="font-secondary text-sm leading-4 text-cta-secondary-dark">
            {getDate(publicationDate)}
          </p>
        </div>
      </NextLink>
    </div>
  );
};

// PropTypes that help dev's to know what props are available to render component
Thumbnail.propTypes = {
  documentId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  documentType: PropTypes.string.isRequired,
  publicationDate: PropTypes.string.isRequired,
  isFavorite: PropTypes.bool,
  thumbnailUrl: PropTypes.string.isRequired,
  thumbnailAlt: PropTypes.string,
  overrideClass: PropTypes.string,
};

Thumbnail.defaultProps = {
  documentId: 'Document id',
  title: 'Title here',
  documentType: 'Document type here',
  publicationDate: '',
  isFavorite: false,
  thumbnailUrl: '',
  thumbnailAlt: '',
  overrideClass: '',
};

export default Thumbnail;

import { Tab } from '@headlessui/react';
import { usePositionContext } from '@search/Facets/FacetComponents/Position';
import { visualFacetConfig } from '@search/Facets/VisualFacetConfig';
import clsx from 'clsx';
import { Fragment } from 'react';
import AssetThumbnail from './AssetThumbnail';

function AssetTabs() {
  const { selectedAsset, setSelectedAsset, selectedTab, setSelectedTab, setAsset } =
    usePositionContext();

  return (
    <Tab.Group
      selectedIndex={selectedTab}
      onChange={(index) => {
        setSelectedTab(index);
        setSelectedAsset(0);
        setAsset(visualFacetConfig[index].assets[0]);
      }}
    >
      <Tab.List className="flex justify-center gap-4 md:gap-10">
        {visualFacetConfig.map((tab, index) => (
          <Tab key={index} as={Fragment}>
            {({ selected }) => (
              <button
                className={clsx(
                  'w-1/3 py-4 text-center font-secondary text-sm font-bold leading-5 tracking-wide',
                  selected ? 'border-b-2 border-accent text-accent outline-none' : '',
                )}
              >
                {tab.title}
              </button>
            )}
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels>
        {visualFacetConfig.map((tab, tabIndex) => (
          <Tab.Panel key={tabIndex}>
            <div className="grid grid-cols-3 gap-4 md:grid-cols-4 lg:grid-cols-7">
              {tab.assets.map((asset, assetIndex) => (
                <AssetThumbnail
                  key={assetIndex}
                  asset={asset}
                  selectedAsset={selectedAsset}
                  setSelectedAsset={setSelectedAsset}
                  setAsset={setAsset}
                />
              ))}
            </div>
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
}

export default AssetTabs;

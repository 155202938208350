import { searchStore } from '@context/searchStore';
import Icon from '@elements/Icon';
import { createSearchQuery } from '@helpers/searchQuery';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

function setSearchFacet(facetFamily, facetValue, searchState, router) {
  const currentMultiFacetState = searchState.facets.multi;
  const existingMultiFacets = currentMultiFacetState?.find(
    (facetObj) => facetObj.name === facetFamily,
  );
  const multiFacets = existingMultiFacets?.value || [];

  if (multiFacets.includes(facetValue)) {
    multiFacets.splice(multiFacets.indexOf(facetValue), 1);
  } else {
    multiFacets.push(facetValue);
  }

  const multiFacetQuery = multiFacets.filter(Boolean).join('+');
  const query = createSearchQuery(facetFamily, multiFacetQuery, router);
  router.push(query.query, query.visibleQuery, query.scroll);
}

function CheckboxIcon({ selected }) {
  return selected ? (
    <div className="flex size-6 items-center justify-center rounded-[3px] border border-accent bg-accent">
      <div className="flex items-center justify-center">
        <Icon iconName="check" />
      </div>
    </div>
  ) : (
    <div className="size-6 rounded-[3px] border border-cta-secondary-dark group-hover:border-accent" />
  );
}

export default function Checkbox(props) {
  const [selected, setSelected] = useState();
  const { option, loading, facetFamily, facets } = props;
  const router = useRouter();

  // Gets the current search store
  const searchState = searchStore((state) => state.searchState);
  const setSearchState = searchStore((state) => state.setSearchState);

  useEffect(() => {
    searchState.facets.multi.length < 1 && setSelected(false);
    searchState.facets.multi.forEach((multiFacet) => {
      if (multiFacet.name == facetFamily && multiFacet.value.includes(option.label)) {
        setSelected(true);
      } else if (multiFacet.name == facetFamily && !multiFacet.value.includes(option.label)) {
        setSelected(false);
      }
    });
  }, [facets]);

  const handleOnClick = () => {
    if (selected) {
      setSearchFacet(facetFamily, option.label, searchState, router);
      setSelected(false);
    } else {
      setSearchFacet(facetFamily, option.label, searchState, router);
      setSelected(true);
    }
  };

  return (
    <div
      className="group cursor-default text-cta-tertiary-dark hover:text-accent"
      key={option.label}
      value={option}
      onClick={() => handleOnClick(facetFamily, option, searchState, setSearchState)}
      role="presentation"
    >
      <div className="flex items-center justify-between">
        <div className="flex justify-start gap-3">
          <div className="text-functional-contrast">
            {loading ? (
              <div className="w-7 text-2xl">
                <Skeleton />
              </div>
            ) : (
              <CheckboxIcon selected={selected} />
            )}
          </div>
          {loading ? (
            <div className="w-48 text-2xl">
              <Skeleton />
            </div>
          ) : (
            <div className="font-secondary font-light">{option.label}</div>
          )}
        </div>
        {loading ? (
          <div className="w-10 text-2xl">
            <Skeleton />
          </div>
        ) : (
          <div className="font-secondary font-light text-[#8B8B8B]">{option.hits}</div>
        )}
      </div>
    </div>
  );
}
